// extracted by mini-css-extract-plugin
export var awsButton = "index-module--awsButton--VQt9T";
export var bottomContainer = "index-module--bottomContainer--Mczqm";
export var cards = "index-module--cards--ggne0";
export var commitmentContainer = "index-module--commitmentContainer--Y5w0J";
export var container = "index-module--container--wtaou";
export var faqbg = "index-module--faqbg--0B3RF";
export var hero = "index-module--hero--ukq-I";
export var innerButton = "index-module--innerButton--8XdUi";
export var integrated_bg = "index-module--integrated_bg--g+YVk";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--9NYno";
export var outerContainerClassName = "index-module--outerContainerClassName--k8caL";
export var roibg = "index-module--roibg--t7fUq";