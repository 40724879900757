import React from "react";
import { PageLayout } from "@components";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./index.module.scss";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { Hero } from "@components/Hero/Hero";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import FeatureSummary from "@components/FeatureSummary/FeatureSummary";
import Margins from "@components/Margins/Margins";
import { RoiV2 } from "@components/RoiV2/RoiV2";
import LabConsumablesCards from "pages/products/lab-consumables/_components/LabConsumablesCards";
import { FaqV2 } from "@components/FAQV2/FaqV2";
import { FaqProps } from "../reports";

import { CardWithIconProps } from "@components/CardWithIcon/CardWithIcon";

const labConsumablesCards: CardWithIconProps[] = [
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_713_2707)">
					<path
						d="M19 2.99976H9C7.89543 2.99976 7 3.89519 7 4.99976V14.9998C7 16.1043 7.89543 16.9998 9 16.9998H19C20.1046 16.9998 21 16.1043 21 14.9998V4.99976C21 3.89519 20.1046 2.99976 19 2.99976Z"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M17 16.9998V18.9998C17 19.5302 16.7893 20.0389 16.4142 20.414C16.0391 20.789 15.5304 20.9998 15 20.9998H5C4.46957 20.9998 3.96086 20.789 3.58579 20.414C3.21071 20.0389 3 19.5302 3 18.9998V8.99976C3 8.46932 3.21071 7.96061 3.58579 7.58554C3.96086 7.21047 4.46957 6.99976 5 6.99976H7"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_713_2707">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0 -0.000244141)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "A source of truth for your inventory",
		description:
			"From reagents to custom item types - we customize to match exactly how it is in the lab.",
		to: "INVENTORY",
		Cta: "Virtual freezers",
	},
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_713_3509)">
					<path
						d="M12 9.99976C12 8.40846 11.3679 6.88233 10.2426 5.75712C9.11742 4.6319 7.5913 3.99976 6 3.99976H3V5.99976C3 7.59105 3.63214 9.11718 4.75736 10.2424C5.88258 11.3676 7.4087 11.9998 9 11.9998H12"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 13.9998C12 12.4085 12.6321 10.8823 13.7574 9.75712C14.8826 8.6319 16.4087 7.99976 18 7.99976H21V8.99976C21 10.5911 20.3679 12.1172 19.2426 13.2424C18.1174 14.3676 16.5913 14.9998 15 14.9998H12"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 19.9998V9.99976"
						stroke="#2C52F7"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_713_3509">
						<rect
							width="24"
							height="24"
							fill="white"
							transform="translate(0 -0.000244141)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "Finally, an ELN built for scientists",
		description:
			"It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
		to: "NOTEBOOK",
		Cta: "Explore ELN",
	},
	{
		icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 1.99976C12.5917 1.99976 13.1713 2.05114 13.7348 2.14969M12 1.99976C6.47715 1.99976 2 6.47691 2 11.9998C2 17.5226 6.47715 21.9998 12 21.9998M12 1.99976V21.9998M17.738 3.80876C18.6922 4.47845 19.5241 5.31065 20.1934 6.26516M21.8501 10.2654C21.9486 10.8287 22 11.4082 22 11.9998C22 12.5913 21.9486 13.1708 21.8501 13.7341M20.1892 17.7404C19.5203 18.6927 18.6896 19.5231 17.7369 20.1915M13.7328 21.8502C13.17 21.9485 12.591 21.9998 12 21.9998"
					stroke="#2C52F7"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		),
		title: "Transparent and scalable pricing",
		description:
			"Finally, simple and predictable pricing that scales with your team.",
		to: "PRICING",
		Cta: "View Pricing",
	},
];

const faqItems: FaqProps[] = [
	{
		question: "How is our data kept safe and secure?",
		answer: "Genemod partners with Amazon Web Services (AWS), giving us access to industry-leading security measures and certifications, including ISO 27001, SOC 1, and SOC 2. Data security is our top priority, and we view it as a fundamental pillar to ensure the integrity of your research is preserved.",
	},

	{
		question: "Does Genemod support compliance with global standards?",
		answer: "Yes, Genemod is specifically designed to comply with 21 CFR Part 11, making it well-suited for use in regulated (GxP) environments. We emphasize not only functionality but also user peace of mind by adhering to GDPR and CCPA regulations, underscoring our commitment to privacy rights and essential privacy obligations.",
	},
	{
		question: "If I accidentally delete data, how can I recover it?",
		answer: "Genemod provides an Archive feature across all platform functions, allowing you to recover deleted data from the Archived items. If you're unable to locate the data, you can always reach out to us, and our team will assist in restoring the data to your workspace.",
	},
];

export default function InformationTechnology() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "security",
			}}
		>
			<Margins className={styles.roibg}>
				<HeroSection />
				<Commitment />
			</Margins>
			<RobustInfrastructure />
			<Margins className={styles.bottomContainer}>
				<ComplianceStandard />
				<RoiV2 />
				<div className={styles.integrated_bg}>
					<JoinInstitutionsV2
						outerContainerClassName={styles.outerContainerClassName}
					/>
				</div>
				<LabConsumablesCards cards={labConsumablesCards} />
				<div className={styles.faqbg}>
					<FaqV2 faqItems={faqItems} />
				</div>
			</Margins>
		</PageLayout>
	);
}

// New component redesign starts here
const HeroSection = () => {
	const hero = {
		title: "Top-grade security for advanced research collaboration",
		description:
			"Protect your innovative work with Genemod’s cutting-edge data security and compliance solutions",
		image: (
			<StaticImage
				// src="../_assets/integration-hero.png"
				src="../../comparisons/_versusHelper/images/integration-hero.png"
				alt="A Central Protocol Hub for Smooth Collaboration"
			/>
		),
	};
	return (
		<Hero
			className={styles.hero}
			{...hero}
			heroTextContainerSize="small"
			tabletTextAlignment="center"
		/>
	);
};
const ComplianceStandard = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "Compliance with global standards",
			image: (
				<StaticImage
					src={"../../../assets/products/platform-dashboard.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Genemod is purpose-built for compliance with 21 CFR Part 11, making it ideal for operations in regulated (GxP) environments",
				"Our commitment to FDA 21 CFR Part 11 regulations reflects our support for research in strictly regulated settings",
				"We prioritize user peace of mind in addition to functionality. We do this by aligning with GDPR and CCPA regulations, demonstrating our dedication to privacy rights and essential privacy obligations",
			],
		},
	];

	return (
		<FeatureCardLayout className={styles.cards}>
			{cards.map((card, index) => {
				return (
					<FeatureCard
						className={styles.container}
						{...card}
						key={index}
						orientation="right"
					/>
				);
			})}
		</FeatureCardLayout>
	);
};
const RobustInfrastructure = () => {
	const FeatureROI = () => (
		<StaticImage
			loading="eager"
			src="../../../assets/products/aws-cloud.png"
			alt="feature summary"
		/>
	);
	const featureSummaryContent = {
		title: "Robust infrastructure with AWS hosting",
		list: [
			"We go beyond identity and device management, specializing in end-to-end infrastructure security and comprehensive information governance",
			"Our partnership with Amazon Web Services (AWS) means that we have access to top-tier security and various certifications, including ISO 27001, SOC 1, and SOC 2",
			"Together with AWS, we’ve created a resilient infrastructure tailored to safeguard sensitive research data",
		],
		imageRight: true,
		img: <FeatureROI />,
	};
	return <FeatureSummary font {...featureSummaryContent} />;
};
const Commitment = () => {
	const cards: FeatureCardProps[] = [
		{
			title: "100% commitment to data protection",
			image: (
				<StaticImage
					src={"../../../assets/products/single-source-of-truth.png"}
					alt="Easily Manage and Submit Orders"
				/>
			),
			list: [
				"Data security is our top priority. Hundreds of leading research institutions trust us with their groundbreaking projects",
				"We don’t just focus on collaboration; we consider security a fundamental pillar so that you can preserve the integrity of your research",
				"We take pride in our enterprise-level security measures, supporting research organizations worldwide in achieving their goals",
			],
		},
	];

	return (
		<FeatureCardLayout className={styles.cards}>
			{cards.map((card, index) => {
				return (
					<FeatureCard
						sectionAlignment
						className={styles.container}
						{...card}
						key={index}
						orientation="right"
					/>
				);
			})}
		</FeatureCardLayout>
	);
};
