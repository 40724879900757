import { StarPattern } from "@assets/icons";
import React from "react";
import { TypographyV2 } from "..";
import * as styles from "./FeatureSummary.module.scss";
import classNames from "classnames";

type Props = {
	img: JSX.Element;
	title: string;
	subtitle?: string;
	list?: string[];
	sublist?: string[];
	imageRight?: boolean;
	imageLeft?: boolean;
	font?: boolean;
	reverse?: boolean;
	applyV4Styles?: boolean;
	titleStylesOverride?: string;
	sectionWrapper?: string;
	contentOverride?: string;
	subtitleStyles?: string;
	listStyles?: string;
	fixImageRight?: boolean;
};

const FeatureSummary = ({
	img,
	title,
	subtitle,
	list,
	sublist,
	fixImageRight,
	imageRight,
	imageLeft,
	font = false,
	reverse = false,
	applyV4Styles = false,
	titleStylesOverride,
	sectionWrapper,
	contentOverride,
	subtitleStyles,
	listStyles,
}: Props) => {
	let contentClassName = classNames(styles.content);

	if (imageRight) {
		contentClassName = classNames(contentClassName, styles.contentRight);
	}

	if (imageLeft) {
		contentClassName = classNames(contentClassName, styles.contentLeft);
	}

	return (
		<div className={styles.container}>
			<div
				className={classNames(styles.main, sectionWrapper,
					fixImageRight && styles.main_right, {
					[styles.reverse]: reverse,
					[styles.mainV4]: applyV4Styles,
				})}
			>
				<div
					className={classNames(styles.responsiveImage, {
						[styles.responsiveImageV4]: applyV4Styles,
						[styles.reverse]: reverse,
					})}
				>
					{img}
				</div>
				<div
					className={classNames(contentClassName, contentOverride, {
						[styles.reverse]: reverse,
						[styles.contentV4]: applyV4Styles,
					})}
				>
					<TypographyV2
						// className={styles.title}
						className={classNames(
							styles.title,
							titleStylesOverride,
							font && styles.font
						)}
						variant="HEADING_2"
						tabletVariant="HEADING_3"
						mobileVariant="HEADING_2"
						weight="MEDIUM"
						color="white"
					>
						{title}
					</TypographyV2>
					{subtitle && (
						<TypographyV2
							className={classNames(
								styles.subtitle,
								subtitleStyles
							)}
							variant="BODY_TEXT_EXTRA_LARGE"
							tabletVariant="BODY_TEXT_LARGE"
							mobileVariant="BODY_TEXT_MEDIUM"
							weight="REGULAR"
							color="white"
						>
							{subtitle}
						</TypographyV2>
					)}
					{!!list?.length && (
						<div className={styles.list}>
							{list?.map((obj, index) => {
								return (
									<div key={index} className={styles.item}>
										<div className={styles.star}>
											<StarPattern />
										</div>
										<div className={styles.itemText}>
											<TypographyV2
												className={classNames(
													font && styles.subtitlefont,
													listStyles,
													{
														[styles.listHeadingV4]:
															applyV4Styles,
													}
												)}
												variant="BODY_TEXT_LARGE"
												color="white"
												weight={
													sublist && !applyV4Styles
														? "MEDIUM"
														: applyV4Styles
														? "SEMIBOLD"
														: "REGULAR"
												}
											>
												{obj}
											</TypographyV2>
											{sublist &&
												sublist.length > 0 &&
												sublist[index] && (
													<TypographyV2
														variant="BODY_TEXT_LARGE"
														weight="REGULAR"
														mobileVariant="BODY_TEXT_LARGE"
														color="white"
														className={classNames(
															listStyles,
															{
																[styles.sublistV4]:
																	applyV4Styles,
															}
														)}
													>
														{sublist[index]}
													</TypographyV2>
												)}
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FeatureSummary;
